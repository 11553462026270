import request from '@/utils/request'

const BASE_PATH = '/customer'

export const login = async (loginForm) => {
  return request.post(`${BASE_PATH}/login`, loginForm)
}
export const getAuth = async () => {
  return request.get('/authority')
}
export const getAllAuth = async () => {
  return request.get('/authority/defaultCustomerAuth')
}
export const getCode = async (codeForm) => {
  return request.post('/verification/getCode', codeForm, { loading: false })
}
export const forgetPwd = async (form) => {
  return request.post('/forgetPwd', form)
}
export const forgetCode = async (phone) => {
  return request.get(`/verification/sms?phone=${phone}`)
}
// customer/getInfo

export function getInfo () {
  return request.get(`${BASE_PATH}/getInfo`)
}

export const logout = async () => {
  return request.get('/logout')
}
export const listURL = async () => {
  return request.get('/external-application/landing/qd-zx')
}

/** 员工接口 */
// 获取列表
export const userList = async (params) => {
  return request.get(`${BASE_PATH}`, { params: params })
}
// 列举员工
export const userData = async () => {
  return request.get(`${BASE_PATH}/list`)
}
// 添加员工
export const addUser = async (userForm) => {
  return request.post(`${BASE_PATH}`, userForm)
}
// 添加员工
export const editUser = async (id, userForm) => {
  return request.put(`${BASE_PATH}/${id}`, userForm)
}
/** 删除员工 */
export const delUser = async (id) => {
  return request.delete(`${BASE_PATH}/${id}`)
}
/** 禁用/启用员工 */
export const enableUser = async (id, params) => {
  return request.get(`${BASE_PATH}/enableOrDisable/${id}`, { params: params })
}
/** 员工详情 */
export const detailUser = async (id) => {
  return request.get(`${BASE_PATH}/${id}`)
}

// 员工详情
export const getUser = async (id) => {
  return request.get(`${BASE_PATH}/${id}`)
}
/** 列举员工 */
export const getUserList = async () => {
  return request.get(`${BASE_PATH}/list`)
}
/** 修改密码 */
export const changePassword = async (form) => {
  return request.put(`${BASE_PATH}/changePassword`, form)
}
/** 获取部门下员工 */
export const getDepartmentCustomer = async (params) => {
  return request.get(`${BASE_PATH}/customerList`, { params: params })
}
