import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import { replaceSymbols } from '@/utils/validate'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets
// import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss' // global css
import '@/icons' 
import '@/assets/style/theme/index.css'
Vue.use(ElementUI, { size: 'small' })

Vue.config.productionTip = false

Vue.prototype.$replaceSymbols = replaceSymbols
// Vue.prototype.$back = () => router.go(-1)
Vue.prototype.$back = () => window.history.back()

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
