import request from "@/utils/request";

/** 登录认证方法 */
export const login = async (username, password, service) => {
  return request.post(
    `/user/login?service=${service}`,
    {
      username: username,
      password: password,
    }
  );
};
