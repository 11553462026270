/**
 * Created by PanJiaChen on 16/11/18.
 */

export const regexs = {
  email: /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/,
  ip: /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/g,
  mobile: /^([1][3-9])\d{9}$/,
  phoneRegWithArea: /^[0][1-9]{2,3}-[0-9]{5,10}$/,
  phoneRegNoArea: /^[1-9][0-9]{5,8}$/,
  number: /^[0-9]+$/,
  integerNumber: /^[1-9]{1}[0-9]*$/, //大于0的正整数
  unsignedNumber: /^[-]?[0-9]+$/,
  decimal: /^[-]?[0-9]+$/,
  unsignedDecimal: /^(\d+)[.]+(\d+)$/,
  positiveInteger: /(^[1-9](\d+)?(\.\d{1,4})?$)|(^[0]\.\d{1,4}?$)/, // 大于0的数值
  account: /^[0-9a-zA-Z]{1,20}$/, // 员工账号的正则，只能输入数字和字母
  pwd: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,15}$/,

  specialSymbol: /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·！￥…（）—《》？：“”【】、；‘’，。]/g, // 特殊符号
  external: /^(https?:|mailto:|tel:)/, // 外链
  link: /^((http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?)/, // 外链
  idCard: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, // 身份证号码正则表达式
  uscc: /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/, // 同时支持18位社会信用代码
  userName: /^[a-zA-Z\u4E00-\u9FA5]+$/, // 匹配中文和英文的用户名
};

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ["admin", "editor"];
  return valid_map.indexOf(str.trim()) >= 0;
}

/** 去除特殊字符 */
export const replaceSymbols = (value) => {
  return value.replace(regexs.specialSymbol, "");
};

export const desensitization = (str, beginLen) => {
  const len = str.length;
  const firstStr = str.substr(0, beginLen);
  let lastStr = "";
  if (len > 4) {
    lastStr = str.substr(-1);
  } else {
    lastStr = "";
  }
  const middleStr = str.substring(beginLen, len - 0).replace(/[\s\S]/gi, "*");
  const tempStr = firstStr + middleStr + lastStr;
  return tempStr;
};
