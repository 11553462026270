import axios from "axios";
import { Loading, Notification } from "element-ui";
import { StatusCodes } from "http-status-codes";
import DelayLoadingService, {
  defaultDelay,
} from "@/components/Form/LoadingService/DelayLoadingService";
// import router from '@/router'

//let isLoading;

// create an axios instance
const request = axios.create({
  baseURL: "/api", // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
});

// request interceptor
request.interceptors.request.use(
  (config) => {
    // do something before request is sent

    //isLoading = Loading.service({})
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

const DEFAULT_ERROR_MSG = "服务器异常，请稍等。。。";
const DEFAULT_ERROR_MSG_TITLE = "系统提示！";
const errorHandler = (response) => {
  let message = DEFAULT_ERROR_MSG;
  if (response && response.data && response.data.msg) {
    message = response.data.msg;
  }
  Notification({
    title: DEFAULT_ERROR_MSG_TITLE,
    message: message,
    type: "error",
  });
};

// response interceptor
request.interceptors.response.use(
  (response) => {
    //isLoading.close()
    if (response.status === StatusCodes.OK) {
      const contentType = response.headers["content-type"] ?? [];
      if (contentType.includes("application/json")) {
        const res = response.data;
        if (res.code) {
          if (res.code === StatusCodes.OK) {
            return res.data;
          } else {
            return Promise.reject(new Error(res.msg));
          }
        } else {
          return res;
        }
      } else {
        // other blob
        return response;
      }
    } else {
      //errorHandler(response)
      return Promise.resolve(new Error(response.statusText));
    }
  },
  (error) => {
    //isLoading.close()
    errorHandler(error.response);
    const code = error.response
      ? error.response.status
      : error.response.data.code;

    // 未登录或登录失效
    if (code === StatusCodes.UNAUTHORIZED) {
      //   router.push('/login')
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

//返回一个代理对象
//对loading问题单独处理
export default new Proxy(request, {
  get(target, propKey, receiver) {
    //拦截
    const origin = Reflect.get(target, propKey);
    if (
      (propKey === "get" ||
        propKey === "post" ||
        propKey === "put" ||
        propKey === "delete") &&
      typeof origin === "function"
    ) {
      return async (...args) => {
        //默认配置选项
        let defaults = {
          loading: true,
          delay: defaultDelay,
        };

        //loading选项的配置
        let loadingArg = args.find(
          (a) => a && a.loading !== null && a.loading !== undefined
        );

        //选项
        let opts = Object.assign(defaults, loadingArg);

        //删除loading配置对应的参数,没必要传输给后台
        if (loadingArg != null) {
          args.splice(args.indexOf(loadingArg), 1);
        }

        try {
          //如果需要显示loading则
          if (opts.loading) {
            DelayLoadingService.getService().openLoading();
          }

          let result = await origin(...args);

          //正确关闭loading
          if (opts.loading) {
            DelayLoadingService.getService().closeLoading(opts.delay);
          }

          //返回结果
          return result;
        } catch (err) {
          //错误,即可关闭loading
          if (opts.loading) {
            DelayLoadingService.getService().closeLoadingImmediate();
          }

          throw err;
        }
      };
    }

    return origin;
  },
});
