import { Loading, Notification } from 'element-ui'

//休眠方法
const sleep = async (second) => {
    return new Promise((resolve, reject) => {
        window.setTimeout(() => {
            resolve()
        }, second)
    })
}

//状态枚举
const StateType = {
    loading: 'loading',
    closing: 'closing',
    closed: 'closed'
}

//默认的延迟时间,这里设置600是根据实际的代码情况来的
export const defaultDelay = 600;

//延迟关闭的Loading服务
export default class DelayLoadingService {


    static _service = null;

    //单例模式
    static getService () {

        if (DelayLoadingService._service === null) {
            DelayLoadingService._service = new DelayLoadingService();
        }

        return DelayLoadingService._service;
    }

    //loading的组件
    loadingComponent = null;

    //loading的状态
    loadingState = StateType.closed;


    getComponent = () => {
        return this.loadingComponent;
    }

    getState = () => {
        return this.loadingState
    }

    openLoading = () => {

        if (this.loadingState === StateType.closed) {
            //若关闭则打开
            this.loadingComponent = Loading.service({});
            this.loadingState = StateType.loading;
        }

        else if (this.loadingState === StateType.closing) {
            //若关闭中则重新打开
            this.loadingState = StateType.loading;
        }
        else {

        }
    }
    //立即关闭loading
    closeLoadingImmediate = () => {
        if (!this.loadingComponent) {
            //若loading组件未初始化则直接返回
            return;
        }
        //立即关闭
        this.loadingComponent.close();
        this.loadingState = StateType.closed;
    }

    //延时关闭loading
    closeLoading = async (delay = defaultDelay) => {

        if (!this.loadingComponent) {
            //若loading组件未初始化则直接返回
            return;
        }

        if (this.loadingState === StateType.loading) {

            //先标记为关闭中,此时并未实际关闭
            this.loadingState = StateType.closing;

            //默认停${delay}秒
            await sleep(delay);

            //等几秒后确认没有被再次打开loading
            if (this.loadingState === StateType.closing) {
                //double check 真正的关闭窗口
                if (this.loadingComponent) {
                    this.loadingComponent.close();
                }
                //加载状态
                this.loadingState = StateType.closed;
            }
        }

    }

}


//测试延迟loading的效果(test)
export const call = async () => {

    //测试loading的效果

    DelayLoadingService.getService().openLoading();

    DelayLoadingService.getService().openLoading();

    DelayLoadingService.getService().closeLoading();

    await sleep(500);

    DelayLoadingService.getService().openLoading();

    DelayLoadingService.getService().closeLoading();

}